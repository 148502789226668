import {
  Box,
  Heading,
  Text,
  VStack,
  SimpleGrid,
  Image,
  HStack,
} from "@chakra-ui/react";
import React from "react";
import { PrimaryButton } from "../../../components/design_library";
import { BiRightArrowAlt } from "react-icons/bi";

export default function IndustryProfessionals() {
  return (
    <VStack
      justifyContent="center"
      textAlign="center"
      spacing={8}
      mt={{
        base: "0px",
        md: "0px",
      }}
      mb="180px"
    >
      <Heading as="h2" size="xl">
        Supports all major brokers
      </Heading>
      <Text maxW={"500px"}>
        Our trading journal supports all major brokers, including Schwab,
        TastyWorks, Interactive Brokers, ETrade, and TD Ameritrade.
      </Text>
      <HStack
        w="8xl"
        maxW="90vw"
        mt="20px"
        justifyContent={"center"}
        spacing={24}
        wrap={"wrap"}
      >
        <VStack>
          <Image
            borderRadius={4}
            src="https://cdn.wealthbee.io/main/brokers/broker_icons/schwab.png"
            alt="Charles Schwab Trading Journal"
            boxSize="60px"
            objectFit="contain"
            m="auto"
          />
          <Text>Charles Schwab</Text>
        </VStack>

        <VStack>
          <Image
            borderRadius={4}
            src="https://cdn.wealthbee.io/main/brokers/broker_icons/tastyworks.png"
            alt="Tastyworks Trading Journal"
            boxSize="60px"
            objectFit="contain"
            m="auto"
          />
          <Text>Tastyworks</Text>
        </VStack>
        <VStack>
          <Image
            borderRadius={4}
            src="https://cdn.wealthbee.io/main/brokers/broker_icons/interactive-brokers.png"
            alt="Interactive Brokers Trading Journal"
            boxSize="60px"
            objectFit="contain"
            m="auto"
          />
          <Text>Interactive Brokers</Text>
        </VStack>
        <VStack>
          <Image
            borderRadius={4}
            src="https://cdn.wealthbee.io/main/brokers/broker_icons/etrade.png"
            alt="ETrade Trading Journal"
            boxSize="60px"
            objectFit="contain"
            m="auto"
          />
          <Text>ETrade</Text>
        </VStack>
        <VStack>
          <Image
            borderRadius={4}
            src="https://cdn.wealthbee.io/main/brokers/broker_icons/td.png"
            alt="TD Ameritrade Trading Journal"
            boxSize="60px"
            objectFit="contain"
            m="auto"
          />
          <Text>TD Ameritrade</Text>
        </VStack>
      </HStack>
    </VStack>
  );
}
